import React from 'react'

import WysiwygPage from '../../../components/Templates/WysiwygPage'
import NoTranslation from '../../../components/Templates/NoTranslation'

import { graphql } from 'gatsby'
import { extract } from '../../../utils/graphql'
import { localizePath } from '../../../../config/i18n'
import { withPreview } from 'gatsby-source-prismic'

const ContentPage = ({ data, pageContext }) => {
  const { prismicBlockchain101 } = data
  if (!prismicBlockchain101) return (<NoTranslation></NoTranslation>)

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(prismicBlockchain101),
    mantelData: extract.mantel(prismicBlockchain101, { isThick: true,
      parent: {
        path: localizePath(pageContext.locale, '/what-we-do'),
        text: 'What We Do'
      }
    }),
    content: prismicBlockchain101.data.content.html
  }
  return (
    <WysiwygPage { ...hydratedData } />
  )
}

export default withPreview(ContentPage)

export const pageQuery = graphql`
  query Blockchain101ByLocale( $locale: String! ) {
    prismicBlockchain101( lang: { eq: $locale } ) {
      uid
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        content {
          html
        }
      }
    }
  }
`
